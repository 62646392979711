<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<router-link
					:to="{ name: 'TagFilterCreate' }"
					class="btn btn-secondary mb-3"
				>
					<CIcon class="mr-2" name="cil-plus" />
					Create tag filter
				</router-link>
			</ccol>
		</CRow>
		<!-- Filter -->
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by tag name, tag id"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Date"
									placeholder="All periods"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.status"
									:options="TAG_FILTER_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Status"
									class="select-custom"
									@input="handleStatusChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.period_status"
									:options="TAG_FILTER_PERIOD_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Period"
									class="select-custom"
									@input="handlePeriodStatusChange"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="meta.total"
					append-text="Tag filter(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<!-- End Filter -->

		<!-- Table -->
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage
					}"
					:fields="TAG_FILTER_TABLE_FIELDS"
					:items="dataTable"
					clickable-rows
					:link-to="ROUTE_NAME.TAG_FILTER_EDIT"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "tag filters" }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge
							class="badge-status"
							:color="item.value.toLowerCase()"
						>
							{{ item.name }}
						</CBadge>
					</template>
					<template #periodStatus="{item}">
						<CBadge
							class="badge-status"
							:color="item.color"
						>
							{{ item.title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<!-- End Table -->
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
	TAG_FILTER_STATUS_OPTIONS,
	TAG_FILTER_PERIOD_STATUS_OPTIONS,
	TAG_FILTER_BADGE_COLOR,
	TAG_FILTER_TABLE_FIELDS,
} from '../enums/tagFilters';
import { ROUTE_NAME } from '../enums/route';

import { randomSearchString, hasSearchFilter, cleanObject, cloneDeep, convertDateRangeToUTC } from '../assets/js/helpers';

export default {
	name: 'TagFilterList',

	data() {
		return {
			TAG_FILTER_STATUS_OPTIONS,
			TAG_FILTER_PERIOD_STATUS_OPTIONS,
			TAG_FILTER_BADGE_COLOR,
			TAG_FILTER_TABLE_FIELDS,

			ROUTE_NAME,

			queryParams: {
				q: this.$route.query.q || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				status: this.$route.query.status,
				period_status: this.$route.query.period_status,
				page: this.$route.query.page ? Number(this.$route.query.page) : null,
				r: randomSearchString(),
			},

			dropdown: {
				status: this.getSelectedValue(this.$route.query.status, TAG_FILTER_STATUS_OPTIONS),
				period_status: this.getSelectedValue(this.$route.query.period_status, TAG_FILTER_PERIOD_STATUS_OPTIONS),
			},

			isShowFilter: false,
		};
	},

	computed: {
		...mapState('tagFilters', {
			list: 'list',
		}),

		isLoading() {
			return this.list.isLoading;
		},

		meta() {
			return this.list.meta;
		},

		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},

		dateRange() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},

		dataTable() {
			return this.list.data.map((item) => {
				return {
					id: item.id,
					name: item.name,
					periodStatus: item.periodStatus,
					startAt: item.startAt,
					endAt: item.endAt,
					priority: item.priority,
					status: item.status,
				};
			});
		},
	},

	async created() {
		this.isShowFilter = this.hasFilter;

		await this.fetchData();
	},

	methods: {
		...mapActions({
			getTagFilters: 'tagFilters/getTagFilters',
		}),

		fetchData() {
			this.getTagFilters(this.queryParams);
		},

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleStatusChange({ value = null }) {
			this.queryParams.status = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePeriodStatusChange({ value = null }) {
			this.queryParams.period_status = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},
		handlePageChange(pageNum = null) {
			this.queryParams.page = pageNum;
			this.updateUrlParams();
		},
		// get selected value, use with BaseDropDown components
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				start_at: null,
				end_at: null,
				status: null,
				period_status: null,
				page: null,
				r: null,
			};
			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
.filter-box {
	background-color: $color-gray-100;
}
</style>
