import { render, staticRenderFns } from "./TagFilterList.vue?vue&type=template&id=0b4d98c7&scoped=true&"
import script from "./TagFilterList.vue?vue&type=script&lang=js&"
export * from "./TagFilterList.vue?vue&type=script&lang=js&"
import style0 from "./TagFilterList.vue?vue&type=style&index=0&id=0b4d98c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4d98c7",
  null
  
)

export default component.exports